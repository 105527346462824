import React from "react";
import "./Event.css";
import Navbar from "../../commons/Navbar";
import useMediaQuery from "@mui/material/useMediaQuery";
import Map from "../../resources/images/map.svg";
import Check from "../../resources/images/tick.svg";
import Line from "../../resources/images/line.svg";
import Ellipse1 from "../../resources/images/top_ellipse_1.svg";
import "./EventMobile.css";
import ItCard1 from "../../resources/images/it-card-1.svg";
import ItCard2 from "../../resources/images/it-card-2.svg";
// import { useSwipeable } from "react-swipeable";
import { otherData, spitiData } from "./helper";
import RedCross from "../../resources/images/red-cross.svg";
import { useParams } from "react-router-dom";
import EmptyStar from "../../resources/images/empty-star.svg";
import FilledStar from "../../resources/images/filled-star.svg";
import Location from "../../resources/images/location2.svg";
import ReviewIcon1 from "../../resources/images/review-icon-1.svg";
import ReviewIcon2 from "../../resources/images/review-icon-2.svg";
import ReviewIcon3 from "../../resources/images/review-icon-3.svg";
import FillStar from "../../resources/images/review-icon-4.svg";
import ReviewIcon5 from "../../resources/images/value-icon.svg";
import EmtStar from "../../resources/images/emt-star.svg";

export default function Event() {
  const params = useParams();
  const dest = params.id;
  const data = dest === "spiti" ? spitiData : otherData;
  const isMobile = useMediaQuery("(max-width:600px)");
  // const [selectedReview, setSelectedReview] = useState(0);
  // const [isTransitioning, setIsTransitioning] = useState(false);
  // const [direction, setDirection] = useState(0);
  const destinationSection = () => {
    return (
      <div
        className="event-destination-section"
        style={{ marginBottom: 24, marginTop: isMobile ? 72 : 218 }}
      >
        <p className="event-destination-section-title">THE DESTINATION</p>
        <div className="event-destination-section-content">
          {!isMobile && (
            <img
              src={data.coverImgs[0]}
              alt="test-destination"
              className="destination-image1"
            />
          )}
          <img
            src={data.coverImgs[1]}
            alt="test-destination"
            className="destination-image2"
          />
          <img
            src={data.coverImgs[2]}
            alt="test-destination"
            className="destination-image3"
          />
          <img
            src={data.coverImgs[3]}
            alt="test-destination"
            className="destination-image4"
          />
          {!isMobile && (
            <img
              src={data.coverImgs[4]}
              alt="test-destination"
              className="destination-image5"
            />
          )}
        </div>

        <p
          className="destination-section-title"
          style={{ marginTop: 47, textAlign: "center" }}
        >
          {data.destinationTitle}
        </p>
        <p className="destination-section-description">
          {data.destinationText}
        </p>
      </div>
    );
  };

  const duration = (margin: number, mobileMargin: number) => {
    return (
      <div
        className="event-duration-section"
        style={{ marginTop: isMobile ? mobileMargin : margin }}
      >
        <div
          style={{
            display: isMobile ? "grid" : "flex",
            gap: 24,
            justifyContent: "space-between",
            gridTemplateColumns: isMobile ? "repeat(1, 1fr)" : "auto",
          }}
        >
          {data.tiles.map((tile, index) => (
            <div key={index} className="event-duration-card">
              <img
                src={tile.icon}
                alt="icon"
                style={{ width: 60, height: 60 }}
              />
              <div>
                <p className="duration-card-text">{tile.text}</p>
                <p className="duration-card-text" style={{ fontWeight: 400 }}>
                  {tile.subtext}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const aboutTour = () => {
    const multiLineText = data.about
      .split("\n")
      .map(
        (
          line:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | Iterable<React.ReactNode>
            | React.ReactPortal
            | null
            | undefined,
          index: React.Key | null | undefined
        ) => (
          <p key={index} style={{ marginTop: 0 }}>
            {line}
            <br />
          </p>
        )
      );
    return (
      <div className="event-about-tour-section">
        <p className="event-section-title" style={{ marginTop: 47 }}>
          About this tour
        </p>
        <p
          className="event-about-tour-section-description"
          style={{ marginTop: 23 }}
        >
          {multiLineText}
        </p>
      </div>
    );
  };

  const itinerary = () => {
    const itnCard = (itn: any, index: number) => {
      const multiLineText = itn.text
        .split("\n")
        .map(
          (
            line:
              | string
              | number
              | boolean
              | React.ReactElement<
                  any,
                  string | React.JSXElementConstructor<any>
                >
              | Iterable<React.ReactNode>
              | React.ReactPortal
              | null
              | undefined,
            index: React.Key | null | undefined
          ) => (
            <p key={index} style={{ marginTop: 24 }}>
              {line}
            </p>
          )
        );
      return (
        <div className="event-itinerary-card" style={{ background: itn.color }}>
          <div>
            <p className="itinerary-card-title" style={{ minHeight: 40 }}>
              {itn.title}
            </p>
            <div
              style={{
                display: "flex",
                // justifyContent: "center",
                gap: 15,
                marginTop: 36,
                marginBottom: 34,
              }}
            >
              <img
                src={itn.image}
                alt="itn-image"
                className="itinerary-card-image"
              />
              <div>
                <img src={ItCard1} alt="it-card-1" />
                <p className="it-card-text" style={{ marginTop: 7 }}>
                  Travel Style
                </p>
                <p className="it-card-text-1">Urban</p>
                <img src={ItCard2} alt="it-card-2" style={{ marginTop: 25 }} />
                <p className="it-card-text-1" style={{ marginTop: 2 }}>
                  Activty Level
                </p>
                <div className="it-card-text-2" style={{ marginTop: 2 }}>
                  Moderate
                </div>
              </div>
            </div>
            <p className="itinerary-card-description">{multiLineText}</p>
            <button className="itinerary-card-button" onClick={handleShare}>
              Share this trip with somoone who would lowe it.
            </button>
          </div>
        </div>
      );
    };

    return (
      <div
        className="event-itinerary-section"
        style={{ marginTop: isMobile ? 47 : 90 }}
      >
        <p className="event-section-title">Itinerary</p>
        <div
          style={{
            display: "flex",
            gap: 79,
            overflowX: "auto",
            marginTop: isMobile ? 22 : 45,
          }}
        >
          {data.itinerary.map((itn, index) => itnCard(itn, index))}
        </div>
      </div>
    );
  };

  const line = (margin: number, mobileMargin: number) => {
    return (
      <div
        className="event-line"
        style={{ marginTop: isMobile ? mobileMargin : margin }}
      />
    );
  };

  const highlight = () => {
    return (
      <div className="event-highlight-section">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: isMobile ? 72 : 24,
            flexWrap: isMobile ? "wrap" : "nowrap",
          }}
        >
          <div>
            <p className="event-section-title">Highlights</p>
            <div>
              {data.highlights.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: 25,
                    marginTop: 34,
                    alignItems: "center",
                  }}
                >
                  <img src={Check} alt="check" />
                  <p className="highlight-text">{item}</p>
                </div>
              ))}
            </div>
          </div>
          <div>
            {data.map ? (
              <img src={data.map} alt="map" className="map-div-1" />
            ) : (
              <img src={Map} alt="map" className="map-div-1" />
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleShare = async () => {
    // const currentUrl = window.location.href;
    // navigator.clipboard.writeText(currentUrl).then(() => {
    //   setShowSnackbar(true);
    // });
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check this out!",
          text: "Thrivia",
          url: window.location.href, // current URL
        });
        console.log("Shared successfully");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API not supported.");
      // Fallback: handle non-supported browsers here (e.g., show a modal with copy link)
    }
  };

  const amenities = () => {
    return (
      <div className="event-amenities-section" style={{ marginTop: 66 }}>
        <p className="event-section-title">Includes/ Excludes</p>
        <div
          style={{
            display: "flex",
            gap: isMobile ? -10 : 24,
            justifyContent: "space-between",
            flexWrap: isMobile ? "wrap" : "nowrap",
          }}
        >
          <div>
            {data.includes.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  gap: 25,
                  marginTop: 34,
                  alignItems: "center",
                }}
              >
                <img src={Check} alt="check" />
                <p className="highlight-text">{item}</p>
              </div>
            ))}
          </div>
          <div>
            {data.excludes.map((item, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  gap: 25,
                  marginTop: 34,
                  alignItems: "center",
                }}
              >
                <img
                  src={RedCross}
                  alt="cross"
                  style={{ width: 20, height: 20 }}
                />
                <p className="highlight-text">{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  // const handleReview = (index: number) => {
  //   // if (index < 0) index = reviewData.length - 1;
  //   // else if (index >= reviewData.length) index = 0;
  //   // setSelectedReview(index);
  //   if (isTransitioning) return;
  //   setIsTransitioning(true);
  //   setDirection(index > selectedReview ? 1 : -1);
  //   setTimeout(() => {
  //     if (index < 0) index = reviewData.length - 1;
  //     else if (index >= reviewData.length) index = 0;
  //     setSelectedReview(index);
  //     setIsTransitioning(false);
  //   }, 300);
  // };
  // const handlers = useSwipeable({
  //   onSwipedLeft: () => handleReview(selectedReview + 1), // Swipe left to go forward
  //   onSwipedRight: () => handleReview(selectedReview - 1), // Swipe right to go backward
  //   // preventDefaultTouchmoveEvent: true,
  //   trackMouse: true, // Allows swiping with the mouse as well
  // });
  // const reviews = () => {
  //   const currentReview = reviewData[selectedReview];

  //   return (
  //     <div
  //       style={{
  //         marginTop: isMobile ? 86 : 160,
  //         marginBottom: isMobile ? 60 : 100,
  //       }}
  //     >
  //       <p className="review-title">
  //         {isMobile ? "Reviews" : "What Our Clients Say About Us"}
  //       </p>
  //       <div
  //         style={{
  //           marginTop: isMobile ? 43 : 67,
  //           display: "flex",
  //           gap: isMobile ? 33 : 80,
  //         }}
  //         {...handlers}
  //         className={`review-content ${
  //           isTransitioning
  //             ? direction > 0
  //               ? "slide-out-left"
  //               : "slide-out-right"
  //             : "slide-in"
  //         }`}
  //         // className={`review-content ${
  //         //   isTransitioning ? "fade-out" : "fade-in"
  //         // }`}
  //       >
  //         <div
  //           style={{
  //             display: "flex",
  //             flexDirection: "column",
  //             alignItems: "flex-end",
  //           }}
  //         >
  //           <p className="review-text">{currentReview.text}</p>
  //           {!isMobile && (
  //             <p
  //               className="review-subtext"
  //               style={{
  //                 marginTop: 34,
  //                 display: "flex",
  //                 flexDirection: "column",
  //                 alignItems: "flex-end",
  //               }}
  //             >
  //               {currentReview.subtext}
  //             </p>
  //           )}
  //         </div>
  //         <div style={{ display: "flex", gap: 34 }}>
  //           <div style={{ position: "relative" }}>
  //             <div
  //               style={{
  //                 background: "#FFEBC5",
  //                 width: isMobile ? 98 : 185,
  //                 height: isMobile ? 77 : 160,
  //                 borderRadius: 12,
  //               }}
  //             ></div>
  //             <img
  //               src={TestReview}
  //               alt="test-review"
  //               style={{
  //                 position: "absolute",
  //                 top: isMobile ? 10 : 21,
  //                 left: isMobile ? 14 : 27,
  //                 borderRadius: 12,
  //                 height: isMobile ? 77 : 160,
  //                 width: isMobile ? 98 : 185,
  //               }}
  //             />
  //           </div>
  //           {!isMobile && (
  //             <div style={{ marginLeft: 34 }}>
  //               <p className="review-name" style={{ width: "fit-content" }}>
  //                 {currentReview.name}
  //               </p>
  //               <p className="review-company" style={{ width: "fit-content" }}>
  //                 {currentReview.company}
  //               </p>
  //               <p
  //                 className="review-person-link"
  //                 style={{ marginTop: 16, cursor: "pointer" }}
  //               >
  //                 {currentReview.instagram}
  //               </p>
  //               <div style={{ display: "flex", gap: 11, marginTop: 42 }}>
  //                 <div className="review-social-icon">
  //                   <img src={Facebook} alt="facebook" />
  //                 </div>
  //                 <div className="review-social-icon">
  //                   <img src={Instagram} alt="instagram" />
  //                 </div>
  //                 <div className="review-social-icon">
  //                   <img src={Twitter} alt="twitter" />
  //                 </div>
  //               </div>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //       {isMobile && (
  //         <div style={{ display: "flex", gap: 22 }}>
  //           <div style={{ marginTop: 22 }}>
  //             <p className="review-name" style={{ width: "fit-content" }}>
  //               {currentReview.name}
  //             </p>
  //             <p className="review-company" style={{ width: "fit-content" }}>
  //               {currentReview.company}
  //             </p>
  //             <p
  //               className="review-person-link"
  //               style={{
  //                 marginTop: 16,
  //                 cursor: "pointer",
  //                 width: "fit-content",
  //               }}
  //             >
  //               {currentReview.instagram}
  //             </p>
  //           </div>
  //           <div style={{ display: "flex", gap: 11, marginTop: 42 }}>
  //             <div className="review-social-icon">
  //               <img src={Facebook} alt="facebook" />
  //             </div>
  //             <div className="review-social-icon">
  //               <img src={Instagram} alt="instagram" />
  //             </div>
  //             <div className="review-social-icon">
  //               <img src={Twitter} alt="twitter" />
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //       <div style={{ display: "flex", justifyContent: "center" }}>
  //         <div
  //           style={{
  //             marginTop: isMobile ? 105 : 132,
  //             display: "flex",
  //             gap: 36,
  //             alignItems: "center",
  //             alignSelf: "center",
  //           }}
  //         >
  //           <img
  //             src={Chevron}
  //             alt="chevron"
  //             style={{ transform: "rotate(0deg)", cursor: "pointer" }}
  //             onClick={() => {
  //               handleReview(selectedReview - 1);
  //             }}
  //           />
  //           <div style={{ display: "flex", gap: 20 }}>
  //             {reviewData.map((_, index) => (
  //               <div
  //                 key={index}
  //                 style={{
  //                   width: 17,
  //                   height: 17,
  //                   borderRadius: "100%",
  //                   background:
  //                     selectedReview === index ? "#525252" : "#C5C5C5",
  //                   cursor: "pointer",
  //                 }}
  //                 onClick={() => {
  //                   handleReview(index);
  //                 }}
  //               />
  //             ))}
  //           </div>
  //           <img
  //             src={Chevron}
  //             alt="chevron"
  //             style={{ transform: "rotate(180deg)", cursor: "pointer" }}
  //             onClick={() => {
  //               handleReview(selectedReview + 1);
  //             }}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  const topSection = () => {
    return (
      <div style={{ marginTop: 33, position: "relative" }}>
        <p className="title-top-section">
          THE <span className="red-circle-text">{data.destination}</span>{" "}
          OVERLANDING
          <br /> EXPEDITION 2024
          <br /> & <span className="red-circle-text">Creators</span>
        </p>
        <img
          src={Ellipse1}
          alt="ellipse1"
          style={{
            position: "absolute",
            top: 140,
            right: isMobile ? "calc(50% - 59px)" : 118,
          }}
        />
        <img
          src={Line}
          alt="line"
          style={{
            position: "absolute",
            left: 0,
            bottom: isMobile ? 264 : 0,
            width: "100vw",
          }}
        />
        <div
          style={{
            display: "flex",
            gap: isMobile ? 8 : 45,
            marginTop: isMobile ? 148 : 98,
            marginLeft: isMobile ? 7 : 31,
            flexWrap: isMobile ? "wrap" : "nowrap",
          }}
        >
          <img
            src={data.creatorImages[0]}
            alt="test-creator1"
            className="creator-image1"
          />
          <img
            src={data.creatorImages[1]}
            alt="test-creator2"
            className="creator-image2"
            style={{ marginTop: isMobile ? 50 : 39 }}
          />
          <img
            src={data.creatorImages[2]}
            alt="test-creator1"
            className="creator-image3"
            style={{ marginTop: isMobile ? 41 : 39, objectPosition: "top" }}
          />
          <p className="creator-text" style={{ marginLeft: 56 }}>
            The title of ‘creator’ belongs to people who, beyond influencer
            recognition, are out there creating new experiences
          </p>
        </div>
      </div>
    );
  };

  const tripReviews = () => {
    const reviewIconArr = [
      {
        icon: ReviewIcon2,
        rating: 4.9,
        title: "Cleanliness",
      },
      {
        icon: ReviewIcon1,
        rating: 4.9,
        title: "Accuracy",
      },
      {
        icon: ReviewIcon3,
        rating: 4.9,
        title: "Checkin",
      },
      {
        icon: Location,
        rating: 4.9,
        title: "Location",
      },
      {
        icon: ReviewIcon5,
        rating: 4.9,
        title: "Value",
      },
    ];

    const leftSection = () => {
      return (
        <div className="overview-review-container-div-right">
          {reviewIconArr.map((i) => (
            <div className="overview-review-container-div-right-div">
              <div className="overview-review-container-div-right-div-icon">
                <img src={i.icon} alt="review-icon" className="review-icon" />
              </div>
              <p className="overview-review-container-div-right-div-text">
                {i.title}
              </p>
              <p className="overview-review-container-div-right-div-subtext">
                {i.rating}
              </p>
            </div>
          ))}
        </div>
      );
    };
    const headerSection = (header: string) => {
      return <p className="event-page-header-text">{header}</p>;
    };
    return (
      <div style={{ marginTop: 60 }}>
        {headerSection("Reviews (24)")}
        <div className="overview-review-container">
          <div className="overview-review-container-div-left">
            <p className="overview-review-rating-text">4.1</p>
            <div className="overview-review-rating-div">
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={FilledStar} alt="filled-star" />
              <img src={EmptyStar} alt="empty-star" />
            </div>
            <p className="overview-review-rating-subtext">24 ratings</p>
          </div>
          {!isMobile && leftSection()}
        </div>
        <div className="detail-review-container">
          {[...data.reviews].map((i) => (
            <div className="detail-review-container-div">
              <div className="detail-review-container-div-image-wrapper">
                <img
                  src={i.image}
                  alt="review"
                  className="detail-review-container-div-image"
                />
                <p className="detail-review-container-div-name">{i.name}</p>
              </div>
              <div className="detail-review-container-div-date-wrapper">
                {i.star && (
                  <div className="detail-review-container-div-star-wrapper">
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img src={FillStar} alt="filled-star" />
                    <img
                      src={EmtStar}
                      alt="empty-star"
                      style={{ opacity: 0.24 }}
                    />
                  </div>
                )}
                <p className="detail-review-container-div-date">{i.date}</p>
              </div>
              <p className="detail-review-container-div-text">{i.text}</p>
              <p className="detail-review-container-div-read-more">Show more</p>
            </div>
          ))}
        </div>
        {isMobile && leftSection()}
        {!isMobile && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button className="show-more-button">Load more reviews</button>
          </div>
        )}
      </div>
    );
  };
  return (
    <div>
      <Navbar isMobile={isMobile} button={"book"} />
      {topSection()}
      {destinationSection()}
      <div
        style={{
          marginLeft: isMobile ? 22 : 60,
          marginRight: isMobile ? 22 : 60,
        }}
      >
        {duration(222, 119)}
        {line(76, 39)}
        {aboutTour()}
        {itinerary()}
        {highlight()}
        {/* {duration(100, 54)} */}
        {amenities()}
        {/* {reviews()} */}
        {tripReviews()}
      </div>
    </div>
  );
}
