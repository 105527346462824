// import EventHome from "./screens/event_page/EventHome";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import EventDetail from "./screens/event_detail_page/EventDetail";
import Event from "./screens/event_page_inside/Event";
// import Creator from "./screens/creator/Creator";
import Thanks from "./screens/thanks/Thanks";
import HomeV2 from "./screens/home_v2/HomeV2";
import Discover from "./screens/discover/Discover";
// import Event1 from "./screens/event/Event";
import ExploreCity from "./screens/explore_city/ExploreCity";
import CreatorV2 from "./screens/creator/CreatorV2";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<HomeV2 />} />
          <Route path="/discover" element={<Discover />} />
          <Route path="/discover/:id" element={<ExploreCity />} />
          <Route path="/event/:id" element={<Event />} />
          <Route path="/creator/:id" element={<CreatorV2 />} />
          <Route path="/thanks" element={<Thanks />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
