import Aman from "../../resources/images/aman.webp";
import Samarth from "../../resources/images/samarth.webp";
import Creator1 from "../../resources/images/creator-1.webp";
import SpitiIt1 from "../../resources/images/spiti-it1.webp";
import SpitiIt2 from "../../resources/images/spiti-it2.webp";
import SpitiIt3 from "../../resources/images/spiti-it3.webp";
import SpitiIt4 from "../../resources/images/spiti-it4.webp";
import SpitiIt5 from "../../resources/images/spiti-it5.webp";
import SpitiIt6 from "../../resources/images/spiti-it6.webp";
import SpitiIt7 from "../../resources/images/spiti-it7.webp";
import SpitiIt8 from "../../resources/images/spiti-it8.webp";
import SpitiMap from "../../resources/images/spiti-map.webp";
import Img1 from "../../resources/images/img1.webp";
import Img2 from "../../resources/images/img2.webp";
import Img3 from "../../resources/images/img3.webp";
import Img4 from "../../resources/images/img4.webp";
import Img5 from "../../resources/images/img5.webp";

import OtherIt1 from "../../resources/images/other-it1.webp";
import OtherIt2 from "../../resources/images/other-it2.webp";
import OtherIt3 from "../../resources/images/other-it3.webp";
import OtherIt4 from "../../resources/images/other-it4.webp";
import OtherIt5 from "../../resources/images/other-it5.webp";
import Img6 from "../../resources/images/img6.jpg";
import Img7 from "../../resources/images/img7.jpg";
import Img8 from "../../resources/images/img8.jpg";
import Img9 from "../../resources/images/img9.jpg";
import Img10 from "../../resources/images/img10.jpg";
import Duration from "../../resources/images/duration.png";
import Theme from "../../resources/images/theme.png";
import Accommodation from "../../resources/images/accommodation.png";
import Group from "../../resources/images/group-size.png";
import { reviewArr, reviewArr1 } from "../helper";
export const spitiData = {
  title: "OVERLANDING EXPEDITION 2024 & ",
  destination: "SPITI",
  creatorImages: [Aman, Samarth, Creator1],
  destinationTitle: "Spiti Valley: A Journey Beyond the Ordinary",
  destinationText:
    "Spiti Valley offers an unmatched blend of adventure and serenity, with towering mountains, ancient monasteries, and rugged landscapes.",
  tiles: [
    { text: "Duration", subtext: "8 days", icon: Duration },
    { text: "Theme", subtext: "Adventure", icon: Theme },
    { text: "Accommodation", subtext: "Camping", icon: Accommodation },
    { text: "Group Size", subtext: "15-20", icon: Group },
  ],
  about:
    "We are organizing an Offbeat Overlanding Spiti Expedition! Ready for India's first wild mode adventure?\n\nDitch the usual Spiti trips - we're heading off the beaten path, exploring places even Google Maps hasn't found. This journey is for the strong, the wild, and true travelers ready to uncover Spiti's hidden gems.\n\nUnleash your wild spirit from:\n- Thrilling treks\n- Wim Hof cold dips ❄️🏊‍♂️\n- Milky Way stargazing 🌌 and Astrophotography 📸\n- Bonfire camping 🔥\n- Chandratal Parikrama 🌟\n\nWe're inviting the strong and wild at heart - those tough enough to pitch tents in sub-zero temps, keep their minds in control, and take dips in icy waters at high altitudes. We'll carry our tents, pitch them under the sky, and take refreshing river dips - it's proper wild mode!",
  itinerary: [
    {
      title: "Day 1: Shimla to Kalpa",
      image: SpitiIt1,
      text: "Distance: 230 km | Travel time: 8-9 hours \n\n\n Our Spiti adventure begins from Shimla. As we admire the stunning Kinnaur Himalayas, we'll journey through picturesque landscapes and reach Kalpa by evening.\n\n🏕️ We'll camp in style with our luxury overlanding setup, ensuring maximum comfort with air mattresses, cushions, and brand-new gear from Decathlon.\n\nGet ready for breathtaking views and serene vibes as we kickstart this incredible journey! Did you know? Kalpa is famous for its mesmerizing sunrise over the Kinnaur Kailash range",
    },
    {
      title: "Day 2: Kalpa to Tabo (via Nako) ",
      text: "Distance: 175 km | Travel time: 6-7 hours \n\n Our Spiti adventure continues as we journey from Kalpa to the ancient village of Tabo. Nestled in the serene Spiti Valley, Tabo is home to one of the oldest Buddhist monasteries in India, dating back to 996 AD.\n\nOn the way, we'll pause to admire and immerse ourselves in the beauty of Nako Lake, a serene gem nestled in the mountains.\n\nToday, we enter the Spiti region, leaving behind the beautiful landscapes of Kinnaur. Prepare to be enchanted by the spiritual aura and breathtaking landscapes of Tabo",
      image: SpitiIt2,
    },
    {
      title: "Day 3: Tabo to Dogri ",
      image: SpitiIt3,
      text: "Distance: 60 km | Travel time: 2-3 hours\n\n\nOur Spiti adventure takes us deeper into the remote beauty of the valley as we journey to the hidden gem of Phibuk Dogri, a secluded village far beyond the Lahlung Monastery. \n\nThis is a place where few travelers venture, making it a true off-the-beaten-path experience.\n\nDiscover the untouched charm and serene vibes of this remote village. Did you know? Phibuk Dogri is surrounded by stunning landscapes and offers a glimpse into the simple, traditional lifestyle of the Spiti Valley.",
    },
    {
      title: "Day 4: Dogri to Langza (via Komic) ",
      image: SpitiIt4,
      text: "Distance: 75 km | Travel time: 3-4 hours\n\n\n Our Spiti adventure leads us to the picturesque village of Langza, known for its ancient fossils and the towering Buddha statue overlooking the valley.\n\n✨ Tonight, we'll have a special treat with an astrophotography master class under the clear, star-studded skies of Langza.\n\nGet ready to capture the beauty of the night sky and immerse yourself in the serenity of Langza and Komik. Did you know? Langza is famous for its marine fossils, dating back millions of years when the area was submerged under the Tethys Sea",
    },
    {
      title: "Day 5: Langza to Gette (via Kibber) ",
      image: SpitiIt5,
      text: "Distance: 70 km | Travel time: 3-4 hours\n\n\n Today, our Spiti adventure brings us to the tranquil village of Gette, where we'll experience a day of rejuvenation and exploration.\n\n🧘‍♂️ We'll start the day with a Wim Hof meditation session, followed by an invigorating river dip to awaken our senses.\n\nOur journey continues as we visit the iconic Kibber village, marvel at the stunning Key Monastery, and explore Hikkim, home to the world’s highest post office.Did you know? The Key Monastery is the largest monastery in the Spiti Valley and a stunning example of Tibetan architecture",
    },
    {
      title: "Day 6: Gette to Chandratal",
      image: SpitiIt6,
      text: "Distance: 90 km | Travel time: 6-7 hours \n\n\n Our Spiti adventure ascends to the stunning Chandratal Lake, often referred to as the “Moon Lake” for its crescent shape.\n\n🏔️ Today, we'll take time to acclimatize to the high altitude, ensuring we adjust comfortably to the breathtaking heights of Chandratal.\n\nPrepare to be captivated by the pristine beauty and serene vibes of Chandratal. Did you know? Chandratal is a popular destination for trekkers and nature lovers, offering some of the most stunning views in the Himalayas.",
    },
    {
      title: "Day 7: Chandratal parikrama",
      image: SpitiIt7,
      text: "Distance: 0 km | Travel time: 0 hours\n\n\n Our journey at Chandratal continues with a full day dedicated to exploring this magnificent high-altitude lake.\n\n🚶‍♂️ We'll undertake the Chandratal Parikrama, a sacred circumambulation of the lake, allowing us to fully appreciate its serene beauty and spiritual significance.\n\nEnjoy the tranquil atmosphere and the breathtaking landscapes that make Chandratal a truly unique destination. Did you know? The name Chandratal means “Lake of the Moon” and it is considered sacred by both Hindus and Buddhists",
    },
    {
      title: "Day 8: Chandratal to Manali",
      image: SpitiIt8,
      text: "Distance: 110 km | Travel time: 8-9 hours\n\n\nAs our incredible Spiti adventure comes to a close, we make our way to the charming town of Manali, surrounded by lush green valleys and snow-capped peaks.\n\n🛣️ On our way back, we'll pass through the majestic Kunzum Top, offering breathtaking panoramic views of the Spiti and Lahaul valleys. It's a perfect spot for some final high-altitude memories and photographs.\n\nOur luxury overlanding adventure ends here, but the memories we've created will last a lifetime.",
    },
  ],
  highlights: [
    "Camping in sub-zero temperature",
    "Wim Hof cold dips",
    "Milky-way stargazing and Astrophotogrphy",
    "Chandratal Parikrama",
  ],
  includes: [
    "All the meals during the expedition",
    "Backup support vehicle",
    "All the camping gear needed for overlanding",
    "Activities listed in the description",
  ],
  excludes: [
    "Fuel for personal vehicle",
    "Meals except provided at camping location",
    "Activities not listed in the description",
    "Snacks in the vehicle during the journey",
  ],
  map: SpitiMap,
  coverImgs: [Img1, Img2, Img3, Img4, Img5],
  reviews: reviewArr,
};

export const otherData = {
  title: "HOLI RETREAT 2024 & ",
  destination: "PUSHKAR",
  creatorImages: [Aman, Samarth, Creator1],
  destinationTitle: "Pushkar: A cultural oasis of festivity and serenity",
  destinationText:
    "Pushkar is a vibrant cultural hub, known for its sacred lake, ancient temples, and the lively Pushkar Holi festival, offering visitors a unique blend of spirituality and celebration",
  tiles: [
    { text: "Duration", subtext: "5 days", icon: Duration },
    { text: "Theme", subtext: "Cultural", icon: Theme },
    { text: "Accommodation", subtext: "Dorms", icon: Accommodation },
    { text: "Group Size", subtext: "20-25", icon: Group },
  ],
  about:
    "Join us in Pushkar for a five-day retreat that goes beyond the vibrant colors of Holi. This immersive experience is designed to blend adventure, culture, and personal development. Set in the serene Zostel Homes, you’ll find yourself exploring new skills, connecting deeply with fellow travelers, and experiencing Pushkar's rich heritage.\n\nStart your mornings with meditation, followed by exciting activities like slackline classes and mountaineering basics, pushing both your physical and mental limits. Unleash your creativity in a theatre workshop, where you'll dive deep into self-expression and performance in an intimate setting.\n\nOn Holi, join the colorful celebration in the streets of Pushkar, then continue with a private Holi party at the stay. You’ll feel the joy, music, and spirit of this iconic festival in a way that transcends the ordinary. The adventure continues with a serene camel safari, followed by a bonfire under the stars, indulging in authentic Rajasthani cuisine.\n\nThe retreat ends with a reflective community session, where personal growth and newfound connections take center stage. Whether you’re seeking adventure, cultural immersion, or self-discovery, this Pushkar Holi Experience promises a journey of lasting memories and personal transformation.",
  itinerary: [
    {
      title: "Day 1: Slackline classes, mountaineering basics",
      image: OtherIt1,
      text: "Retreat begins with an exciting mix of physical challenge and mindfulness. Start your day by stepping out of your comfort zone with slackline classes—an experience that tests your balance, focus, and agility.\n\nNext, we dive into mountaineering basics. You’ll learn essential techniques that are not only physically rewarding but mentally empowering. Feel the rush as you engage with the raw elements of nature and push your limits.\n\nAs the day winds down, you'll center yourself with a peaceful meditation session. The tranquility of Pushkar and the serene surroundings will help you reconnect with yourself.",
    },
    {
      title: "Day 2: Theatre experience, a deep dive into acting",
      text: "Today, it’s all about tapping into your creative potential. Immerse yourself in a theatre workshop that takes you deep into the world of acting. Under the guidance of experienced facilitators, you’ll explore the art of expression, storytelling, and performance. Whether you're a seasoned performer or just curious, this experience will allow you to break free from inhibitions and step into a new realm of creativity.\n\nBy the end of the session, you'll feel more connected to yourself and the group, ready to express your newfound confidence in the colorful celebrations to come.",
      image: OtherIt2,
    },
    {
      title: "Day 3: Local Holi experience, after party at stay",
      image: OtherIt3,
      text: "Today, experience the serene beauty of Pushkar’s desert landscapes on a camel safari. As you traverse the golden sands, you’ll feel the peaceful isolation of the desert, with only the gentle sway of the camel and the vast horizon before you. It’s a moment of stillness, allowing you to reflect on your journey so far.\n\nAs the day ends, gather around a bonfire, where the warmth of the flames is matched by the warmth of Rajasthani hospitality. Enjoy an authentic local cuisine, prepared with love and tradition, as stories are shared and connections deepen under the starlit sky.",
    },
    {
      title: "Day 4: Camel safari with bonfire and local cuisine",
      image: OtherIt4,
      text: "Distance: 75 km | Travel time: 3-4 hours\n\n\n Our Spiti adventure leads us to the picturesque village of Langza, known for its ancient fossils and the towering Buddha statue overlooking the valley.\n\n✨ Tonight, we'll have a special treat with an astrophotography master class under the clear, star-studded skies of Langza.\n\nGet ready to capture the beauty of the night sky and immerse yourself in the serenity of Langza and Komik. Did you know? Langza is famous for its marine fossils, dating back millions of years when the area was submerged under the Tethys Sea",
    },
    {
      title: "Day 5: Community development",
      image: OtherIt5,
      text: "Your final day is about taking everything you’ve experienced and reflecting on it with your newfound community. Through open discussions and community development sessions, you’ll share what you’ve learned, both about the world and about yourself. This is a time for personal growth and to solidify the connections you’ve made over the past five days.\n\nEnd the retreat with a feeling of fulfillment, as you look back on an experience that went beyond a typical holiday—it was a journey of self-discovery, skill-building, and cultural immersion",
    },
  ],
  highlights: [
    "Pushkar Holi experience",
    "Slackline and mountaineering workshops",
    "Camel safari and Bonfire",
    "Theater workshops",
  ],
  includes: [
    "Transportation for the activities",
    "Stay in the dessert camps",
    "Equipments for activities available at the property",
    "Activities listed in the description",
  ],
  excludes: [
    "Meals are chargeable ",
    "Expenditure during market visit",
    "Activities not listed in the description",
    "Expenses made during holi outside stay",
  ],
  map: "",
  coverImgs: [Img6, Img7, Img8, Img9, Img10],
  reviews: reviewArr1,
};
